import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from '../services/socket.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  info: string;
  code = ''; // HLAU1B

  private _infoBus: Subscription = new Subscription();

  constructor(private router: Router, private socketService: SocketService) {}

  onLogin() {
    if (this.code && this.code !== '') {
      this.code = this.code.toUpperCase();
      this.socketService.initSocket(this.code);
    } else {
      this.info = 'Bitte code eingeben ';
    }
  }

  ngOnInit() {
    localStorage.clear();
    this.info = undefined;
    this.socketService.closeSocket();
    this._infoBus = this.socketService.infoBus$.subscribe((x) => {
      if (x && x.loginOk) {
        this.info = undefined;
        if (this.code && this.code !== '') {
          this.router.navigate(['/tabs/' + this.code + '/tab1/']);
        }
      }
      if (x && x.loginNOk) {
        this.info = 'Bitte überprüfen sie Ihren Code';
      }
    });
  }

  ionViewDidLeave() {
    this._infoBus.unsubscribe();
  }
}
