import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _waitList$: BehaviorSubject<any>;
  private _errorInfo$: BehaviorSubject<string>;
  private _adminList$: BehaviorSubject<any>;
  private _infoBus$: BehaviorSubject<any>;
  private _intervallId: any;

  // private url = 'http://127.0.0.1:443/';
  private url = 'https://api.p6api.de/';
  private socket: any;

  get isAdmin() {
    return false;
  }

  get adminList$(): Observable<any> {
    return this._adminList$.asObservable();
  }

  get waitList$(): Observable<any> {
    return this._waitList$.asObservable();
  }

  get errorInfo$(): Observable<any> {
    return this._errorInfo$.asObservable();
  }

  get infoBus$(): Observable<any> {
    return this._infoBus$.asObservable();
  }

  constructor(private router: Router) {
    this._waitList$ = new BehaviorSubject<any>(null);
    this._errorInfo$ = new BehaviorSubject<any>(null);
    this._adminList$ = new BehaviorSubject<any>(null);
    this._infoBus$ = new BehaviorSubject<any>(null);
  }

  public checkSocket(namespace): void {
    namespace = namespace.toUpperCase();
    this.socket = io(this.url + namespace);

    if (!this.socket.connected) {
      this.initSocket(namespace);
    } else {
      this._infoBus$.next({ loginOk: true });
    }
  }

  public setInfo(info: any) {
    this._infoBus$.next(info);
  }

  public askRemove(idObj): void {
    this.socket.emit('askForRemove', idObj);
  }

  public addId(idObj): void {
    this.socket.emit('addId', idObj);
  }

  public callId(id): void {
    this.socket.emit('callId', id);
  }

  public removeId(id): void {
    this.socket.emit('removeId', id);
  }

  public askId(name: string, message: string): void {
    this.socket.emit('askForId', {
      name,
      message,
      uid: localStorage.getItem('uid'),
    });
  }

  public getIds(): void {
    this.socket.emit('getIds', 'init');
  }

  public closeSocket() {
    if (this.socket) {
      this.socket.emit('disconnect', true);
      this.socket = undefined;
    }
  }

  private handleErrors(error, namespace) {
    if (namespace) {
      this.checkSocket(namespace);
    }
    // error === 'Invalid namespace'
    console.log('socket error', error);
  }

  public initSocket(namespace): void {
    console.log('init socket', this.socket);

    namespace = namespace.toUpperCase();
    this.socket = io(this.url + namespace);

    this.socket.on('connect_error', (err: any) =>
      this.handleErrors(err, namespace),
    );
    this.socket.on('connect_failed', (err: any) =>
      this.handleErrors(err, namespace),
    );
    this.socket.on('disconnect', (err: any) =>
      this.handleErrors(err, namespace),
    );

    this.socket.on('connect', (data) => {});

    this.socket.on('error', (error) => {
      this.handleErrors(error, namespace);
    });

    this.socket.on('getIds', (data) => {
      const newData = data ? data : {};

      if (localStorage.getItem('uid')) {
        const myId = newData.ids.find(
          (x: any) => x.uid === localStorage.getItem('uid'),
        );
        if (myId) {
          this._infoBus$.next({ id: myId.id });
          localStorage.setItem('id', myId.id);
        }
      }
      this._waitList$.next(newData);
    });

    this.socket.on('adminList', (obj) => {
      this._adminList$.next(obj);
    });

    this._infoBus$.next({ loginOk: true });
  }
}
